#table-page-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 3rem auto;
    height: 100%;
    gap: 0.3rem;
    padding: 0.3rem;
}

#table-area {
    grid-row: 1;
}

#table-mesas {
    grid-row: 2;
}

#talkbubble {
    width: 100px;
    height: 80px;
    background: red;
    position: relative;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

#talkbubble:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 26px;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-right: 26px solid red;
    border-bottom: 13px solid transparent;
}

.drop-mesas {
    max-height: 75vh;
    max-width: 99vw;
}

/* 
.bubble {
    position: relative;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 24px;
    width: 127px;
    background: #fff;
    border-radius: 0;
    padding: 0px;
    text-align: center;
    color: #000;
    height: 100px;
}

.bubble-bottom-left:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 26px solid #fff;
    border-right: 12px solid transparent;
    border-top: 5px solid #fff;
    border-bottom: 9px solid transparent;
    left: 42px;
    bottom: -8px;
}
 */