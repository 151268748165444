.bubble {
    position: relative;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 24px;
    width: 100px;
    background: #fff;
    border-radius: 0;
    padding: 0px;
    text-align: center;
    color: #000;
    height: 50px;
    margin-bottom: 1rem;
}

.bubble-bottom-left:before {
    content: "";
}

.syrup-button {
    color: #8b0f1b;
    border-radius: 5px;
    text-shadow: 0 0 1px black;
    transition: text-shadow 0.2s;
    box-shadow: 0 2px 1px black;
}

.syrup-button:focus {
    outline: none;
}

.syrup-button:hover {
    text-shadow: 0 0 4px red;
    transition: text-shadow 0.2s;
}

.syrup-button:active {
    box-shadow: inset 0 0 0.2em black;
}

.doscolumnas-field-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "field1 field3";
    gap: 5px;
}

.tresfilas-field-grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5px;
}