.syrup-text-input {
    width: 10px;
    flex-grow: 1;
    border: none;
    box-shadow: inset 0 2px 4px gray;
}

.syrup-text-input:focus {
    outline: none;
}

.syrup-text-input:disabled {
    background-color: lightgray;
}