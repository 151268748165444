#order-page-container {
    display: grid;
    grid-template-columns: min(20%, 200px) auto min(32%, 310px);
    grid-template-rows: calc(100vh - 30px);
    gap: 6px;
    padding: 8px !important;
}

#order-page-main-menu button,
#order-page-side-menu button {
    margin: 3px;
}
