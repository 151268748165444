html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-width: 100vw;
  min-height: 100vh;
}

.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}

body {
  background: #000000;
  background: linear-gradient(to top left, #ed5513, #ed5513, #a12610, #ed5513, #ed5513);
}
