@media only screen and (max-width: 768px) {
    #table-page-container {
        height: calc(100vh - 17rem);
        grid-template-columns: 1fr !important;
        grid-template-rows: 3rem calc(80vh - 2rem) !important;
        gap: 0.1rem;
        padding: 0;
    }

    #table-area {
        grid-row: 1;
    }

    #table-mesas {
        grid-row: 2;
    }

    #order-page-receipt-preview {
        grid-row: 3;
    }

    .order-page-panel-header {
        font-size: 0.5em !important;
    }
}