*::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: black;
  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(46, 1, 1, 0.5);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 24px solid rgb(95, 3, 3);
  /* creates padding around scroll thumb */
  background-clip: padding-box;
}

*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}