#customer-page-container {
    display: grid;
    grid-template-columns: min(20%, 200px) auto;
    grid-template-rows: calc(100vh - 5rem);
    gap: 6px;
    padding: 8px !important;
}
.customer-field-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "Nombres Nombres Apellidos" "Telefono Direccion Direccion" "Email Puntos CodigoPostal" "Comentario Comentario Comentario";
    gap: 5px;
}

@media only screen and (max-width: 768px) {
    .customer-field-grid label {
        font-size: 0.5em;
    }
    .customer-field-grid {
        grid-template-columns: 1fr;
        grid-template-areas: "Nombres Nombres Apellidos" "Telefono Direccion Direccion" "Email Puntos CodigoPostal" "Comentario Comentario Comentario";
    }

    #customer-page-container {
        height: 100vh;
        grid-template-columns: 1fr !important;
        grid-template-rows: max(10em, 20vh) calc(80vh - 9rem) !important;
    }

    #main-datos {
        grid-row: 1;
    }

    #order-page-main-menu {
        grid-row: 2;
    }

    #order-page-receipt-preview {
        grid-row: 3;
    }

    .order-page-panel-header {
        font-size: 0.5em !important;
    }
}
