@media only screen and (max-width: 768px) {
    #order-page-container {
        height: 100VH;
        grid-template-columns: 1fr !important;
        grid-template-rows: max(10em, 20vh) 40vh calc(40VH - 30px) !important;
    }

    #main-datos {
        grid-row: 1;
    }

    #order-page-main-menu {
        grid-row: 2;
    }

    #order-page-receipt-preview {
        grid-row: 3;
    }

    .order-page-panel-header {
        font-size: 0.5em !important;
    }
}