.receipt {
    background: white;
    width: 100%;
    padding: 0 20px;
    font-size: 2rem;
}

.company-name {
    font-size: 1.5em;
    font-weight: 600;
}

.company-address {
    font-size: 0.8em;
    color: #777;
    line-height: 1em;
}

.order-number {
    font-size: 2em;
    font-weight: 800;
}