.main-menu-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    gap: 3px;
}

@media only screen and (max-width: 768px) {
    .main-menu-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 576px) {
    .main-menu-grid {
        grid-template-columns: 1fr 1fr;
    }
}