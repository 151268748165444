.order-item-display {
    /*  display: grid;
    grid-template-columns: min-content auto min-content;
    gap: 3px;
    height: fit-content;
    overflow-y: auto;
    align-items: center;
    justify-items: left;
    border: 2px solid transparent;
    box-sizing:border-box; */
    padding: 0px;
}

.order-item-display.selected {
    background: rgb(255, 0, 0);
    /* border: 2px dashed rgb(85, 13, 0); */
    padding: 3px;
}

.order-item-display.item-sinprocesar {
    background: rgb(255, 255, 255);
    padding: 3px;
}

.order-item-display.item-procesado {
    background: rgb(165, 165, 165);
    padding: 3px;
}

.order-item-display.item-devolucion {
    background: rgb(255, 122, 122);
    padding: 3px;
}