.syrup-text-input {
    width: 10px;
    flex-grow: 1;
    border: none;
    box-shadow: inset 0 2px 4px gray;
}

.syrup-text-input:focus {
    outline: none;
}

.topcarditem-field-grid {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    grid-template-rows: auto;
    grid-template-areas: "field1 field2 field3";
    gap: 5px
}

.trescolumnas-field-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "field1 field2 field3";
    gap: 5px
}

.doscolumnas-field-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "field1 field3";
    gap: 5px
}

.tresfilas-field-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5px
}

#relacion-categorias-component-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: calc(100VH - 100px);
    gap: 6px;
    padding: 2px !important;
}